import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { AuthenticationService } from './authentication.service';

import { environment } from '../../../environments/environment';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    apiUrl: string;

    constructor(private authenticationService: AuthenticationService) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.apiUrl = environment.apiUrl + '/api/v1';
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser && currentUser.token && !request.url.includes('amazonaws')) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }
        if (request.url.includes('amazonaws')) {
            request = request.clone({
                setHeaders: {
                    'x-amz-acl': `public-read`
                }
            });
        }

        if (!request.url.includes('amazonaws')) {
            const url = request.url;
            if ( !(url.endsWith('authenticate') || url.endsWith('signup') || url.endsWith('changePass')
                || url.endsWith('forgotPassword') || url.endsWith('verifyToken') ||  url.endsWith('positions/featured')
                || url.endsWith('newCustomer') || url.endsWith('search-jobs') || url.endsWith('locations')
                || url.includes('/users/position/') || url.includes('/invitation') || url.includes('/confirmEmail')
                || url.endsWith('/users/companyCheck/new'))) {
                request = request.clone({
                    url: this.apiUrl +  request.url
                });
            } else {
                this.apiUrl = environment.apiUrl + '/np';
                request = request.clone({
                    url: this.apiUrl +  request.url
                });
            }
        }
        return next.handle(request);
    }
}

export const JwtInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true,
};
