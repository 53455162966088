import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import {ActivatedRoute, Router} from '@angular/router';
import {LocationStrategy} from '@angular/common';

import { User } from '../../_models/model';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthenticationService} from '../../shared/services/index';
@Injectable()
export class HiringManagerGuard implements CanActivate {
    currentUser: User;
    currentUserSubscription: Subscription;
    returnUrl: string;
    constructor(private route: ActivatedRoute,
        private router: Router,
        private locationStrategy: LocationStrategy,
        private authenticationService: AuthenticationService) {
            this.currentUserSubscription = this.authenticationService.currentUser.subscribe(
                user => {
                    this.currentUser = user;
                    // console.log('Current User: ' + JSON.stringify(this.currentUser));
                }
            );
            this.returnUrl = this.locationStrategy.path();
        }

    canActivate() {
        if (this.currentUser && (this.currentUser.type === 'HIRING_MANAGER'
            || this.currentUser.type === 'RECRUITER')) {
            return true;
        }  else if (this.currentUser && !(this.currentUser.type === 'HIRING_MANAGER'
            || this.currentUser.type === 'RECRUITER')) {
                this.returnUrl = '/access-denied';
                this.router.navigate([this.returnUrl]);
            return false;
        } else {
                this.router.navigate(['/login'], { queryParams: { returnUrl: this.returnUrl } } );
                return false;
            }
        }
}
