import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {HttpClient, HttpParams} from '@angular/common/http';
import {User, UserDetails} from './_models/model';
@Injectable()
export class AppService {

    constructor(private http: HttpClient) {}
    getCandidates(itemsPerPage, page, searchStr) {
        const params = new HttpParams().set('limit', itemsPerPage).set('page', page).set('searchQ', searchStr);
        return this.http.get<any>('/candidates', {params: params});
    }
    createCandidate(candidate) {
        return this.http.post<any>('/candidates/create', candidate);
    }
    updateCandidate(candidate, candidateId) {
        return this.http.put<any>('/candidates/' + candidateId, candidate);
    }
    createSubmission(sub) {
        return this.http.post<any>('/submissions/create', sub);
    }
    updateSubmission(sub, subId) {
        return this.http.put<any>('/submissions/' + subId, sub);
    }
    updatePreferences(data) {
        return this.http.put<any>('/users/preferences', data);
    }
    getSubmissions1(itemsPerPage, page, searchStr) {
        const params = new HttpParams().set('limit', itemsPerPage).set('page', page).set('searchQ', searchStr);
        return this.http.get<any>('/submissions', {params: params});
    }
    getSubmissions(data) {
        const params = new HttpParams();
        return this.http.post<any>('/submissions', data);
    }
    getResumes(data) {
        const params = new HttpParams();
        return this.http.post<any>('/submissions/resumes', data);
    }
    getApprovals(itemsPerPage, page, searchStr) {
        const params = new HttpParams().set('limit', itemsPerPage).set('page', page).set('searchQ', searchStr);
        return this.http.get<any>('/approvals', {params: params});
    }
    getJobSearchResults(data) {
        const params = new HttpParams();
        return this.http.post<any>('/users/search-jobs', data);
    }
    getSubmissionById(submissionId) {
        const params = new HttpParams();
        return this.http.get<any>('/submissions/' + submissionId, {params: params});
    }
    getCandidateByEmail(email) {
        const params = new HttpParams();
        return this.http.get<any>('/candidates/email/' + email, {params: params});
    }
    getCandidateById(candidateId) {
        const params = new HttpParams();
        return this.http.get<any>('/candidates/' + candidateId, {params: params});
    }
    getInterviews(itemsPerPage, page, searchStr) {
        const params = new HttpParams().set('limit', itemsPerPage).set('page', page).set('searchQ', searchStr);
        return this.http.get<any>('/interviews', {params: params});
    }
    getInterviewById(interviewId) {
        const params = new HttpParams();
        return this.http.get<any>('/interviews/' + interviewId, {params: params});
    }
    createInterview(interview) {
        return this.http.post<any>('/interviews/create', interview);
    }
    updateInterview(interview, interviewId) {
        return this.http.put<any>('/interviews/' + interviewId, interview);
    }
    changePassword(data) {
        return this.http.post<any>('/users/changePass', data);
    }
    downloadAttachment(timesheet) {
        const params = new HttpParams();
        return this.http.get<any>('/timesheets/attachment/' + timesheet._id, {params: params});
    }
    forgotPassword(data) {
        return this.http.post<any>('/users/forgotPassword', data);
    }
    uploadFile(signedUrl, data, type) {
        const headerOptions = {
            'Content-Type': type
        };
        return this.http.put<any[]>(signedUrl, data, {headers: headerOptions});
    }
    getSignedUrlGet(fileName, fileType) {
        const params = new HttpParams()
            .set('fileName', fileName)
            .set('fileType', fileType);
        return this.http.get<any>('/aws/signedurlget', {params: params});
    }
    getSignedUrlPut(fileName, fileType) {
        const params = new HttpParams()
            .set('fileName', fileName)
            .set('fileType', fileType);
        return this.http.get<any>('/aws/signedurlput', {params: params});
    }
    createUser(user: User) {
        return this.http.post<any>('/users/signup', user);
    }
    updateUser(data) {
        return this.http.put<any>('/users/', data);
    }
    getUserDetails(userId) {
        const params = new HttpParams();
        return this.http.get<UserDetails>('/users/details/' + userId, { params: params });
    }
    getDashboardData() {
        const params = new HttpParams();
        return this.http.get<any>('/reports/dashboard', { params: params });
    }
    getProfileViews(itemsPerPage, page, searchStr) {
        const params = new HttpParams().set('limit', itemsPerPage).set('page', page).set('searchQ', searchStr);
        return this.http.get<any>('/profileViews/', { params: params });
    }
    getLocations(data) {
        const params = new HttpParams();
        return this.http.post<any>('/users/locations', data);
    }
    verifyResetToken(data) {
        return this.http.post<any>('/users/verifyToken', data);
    }
    getAppliedJobs(userId) {
        const params = new HttpParams();
        return this.http.get<any>('/users/positions/applied', { params: params });
    }
    getFeaturedJobs() {
        const params = new HttpParams();
        return this.http.get<any>('/users/positions/featured', { params: params });
    }
    getVendors(itemsPerPage, page, searchStr) {
        const params = new HttpParams().set('limit', itemsPerPage).set('page', page).set('searchQ', searchStr);
        return this.http.get<any>('/vendors/', { params: params });
    }
    getVendor(id) {
        const params = new HttpParams();
        return this.http.get<any>('/vendors/' + id, { params: params });
    }
    searchVendors(searchStr) {
        const params = new HttpParams().set('searchQ', searchStr);
        return this.http.get<any>('/vendors/search', { params: params });
    }
    getDocuments(itemsPerPage, page, searchStr) {
        const params = new HttpParams().set('limit', itemsPerPage).set('page', page).set('searchQ', searchStr);
        return this.http.get<any>('/documents/', { params: params });
    }
    confirmEmail(data) {
        return this.http.post<any>('/users/confirmEmail', data);
    }
    getJobPosition(jobPositionId) {
        const params = new HttpParams();
        return this.http.get<any>('/users/position/' + jobPositionId, {params: params});
    }
    profileView(data) {
        const params = new HttpParams();
        return this.http.post<any>('/profileviews/create', data);
    }
    shareProfileLink(data) {
        const params = new HttpParams();
        return this.http.post<any>('/profileviews/share', data);
    }
    createJobAlert(data) {
        const params = new HttpParams();
        return this.http.post<any>('/job-positions/alerts/create', data);
    }
    updateAccessCode(data) {
        const params = new HttpParams();
        return this.http.put<any>('/users/accesscode', data);
    }
    getJobPositionById(jobPositionId) {
        const params = new HttpParams();
        return this.http.get<any>('/job-positions/' + jobPositionId, {params: params});
    }
    getJobPositions(itemsPerPage, page, searchStr) {
        const params = new HttpParams().set('limit', itemsPerPage).set('page', page).set('searchQ', searchStr);
        return this.http.get<any>('/job-positions', {params: params});
    }
    getOpenPositions(itemsPerPage, page, searchStr) {
        const params = new HttpParams().set('limit', itemsPerPage).set('page', page).set('searchQ', searchStr);
        return this.http.get<any>('/job-positions/open', {params: params});
    }
    updateJobPosition(jobPosition, positionId) {
        return this.http.put<any>('/job-positions/' + positionId, jobPosition);
    }
    createJobPosition(position) {
        return this.http.post<any>('/job-positions/create', position);
    }
    inviteVendor(data) {
        const params = new HttpParams();
        return this.http.post('/vendors/invite/', data);
    }
    inviteMember(data) {
        const params = new HttpParams();
        return this.http.post('/company/invite/', data);
    }
    addCustomer(data) {
        return this.http.post<any>('/users/newCustomer', data);
    }
    checkCompany(data) {
        return this.http.post<any>('/users/companyCheck/new', data);
    }
    getInvitedUser(id) {
        const params = new HttpParams();
        return this.http.get<any>('/users/invitation/' + id, { params: params });
    }
    createDocument(doc) {
        return this.http.post<any>('/documents/create', doc);
    }
    getTeamMembers(itemsPerPage, page, searchStr) {
        const params = new HttpParams().set('limit', itemsPerPage).set('page', page).set('searchQ', searchStr);
        return this.http.get<User>('/vendors/organization/team', { params: params });
    }
    updateApproverStatusForUser(data) {
        return this.http.post<any>('/users/updateApproverStatusForUser', data);
    }
    updateStatusForUser(data) {
        return this.http.post<any>('/users/updateStatusForUser', data);
    }
    updateRoleForUser(data) {
        return this.http.post<any>('/users/updateRoleForUser', data);
    }
    getVendorData(vendorId, type) {
        const params = new HttpParams().set('type', type);
        return this.http.get<User>('/vendors/' + vendorId, { params: params });
    }
    getCandidateNotes(vendorId) {
        const params = new HttpParams();
        return this.http.get<any>('/notes/', { params: params });
    }
    addNotes(data) {
        return this.http.post<any>('/notes/create', data);
    }
    getCandidatesByPositions(type) {
        const params = new HttpParams().set('type', type);
        return this.http.get<any>('/metrics/', { params: params });
    }
    getCounts() {
        const params = new HttpParams();
        return this.http.get<any>('/metrics/counts', { params: params });
    }
    shortlistCandidate(data) {
        return this.http.post<any>('/candidates/shortlist', data);
    }
    removeSL(data) {
        return this.http.put<any>('/candidates/shortlist/remove', data);
    }
    getShortlistedCandidates(itemsPerPage, page, searchStr) {
        const params = new HttpParams().set('limit', itemsPerPage).set('page', page).set('searchQ', searchStr);
        return this.http.get<any>('/candidates/shortlisted/candidates', { params: params });
    }

}
