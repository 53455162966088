import { OuterFooterModule } from './../../shared/modules/outer-footer/outer-footer.module';
import { NoResultsComponent } from './no-results.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [NoResultsComponent],
    exports: [NoResultsComponent]
})
export class NoResultsModule { }
