import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
    AuthGuard,
    HiringManagerGuard,
    CandidateGuard,
    RecruiterGuard
} from './shared/index';
import { AppService } from './app.service';

const routes: Routes = [
    { path: '', loadChildren: './landing/landing.module#LandingModule' },
    { path: 'login', loadChildren: './login/login.module#LoginModule' },

    { path: 'error', loadChildren: './error/error.module#ErrorModule' },
    { path: 'password', loadChildren: './forgot-password/forgot-password.module#ForgotPasswordModule' },
    { path: 'verify-email/:id', loadChildren: './confirm-email/confirm-email.module#ConfirmEmailModule' },
    { path: 'reset-password', loadChildren: './reset-password/reset-password.module#ResetPasswordModule' },
    { path: 'landing', loadChildren: './landing/landing.module#LandingModule' },
    {
        path: 'joblisting',
        loadChildren: './joblisting/joblisting.module#JoblistingModule',
    },
    { path: 'joblisting/:id', loadChildren: './jobs/jobs.module#JobsModule' },
    { path: 'signup', loadChildren: './signup/signup.module#SignupModule' },
    { path: 'invitation/:id', loadChildren: './signup/signup.module#SignupModule' },
    { path: 'account/:id', loadChildren: './signup/signup.module#SignupModule' },
    { path: 'account', loadChildren: './account/account.module#AccountModule' },
    {
        path: 'error',
        loadChildren: './server-error/server-error.module#ServerErrorModule'
    },
    {
        path: 'access-denied',
        loadChildren: './access-denied/access-denied.module#AccessDeniedModule'
    },
    {
        path: 'features',
        loadChildren: './features/features.module#FeaturesModule'
    },
    {
        path: 'terms-conditions',
        loadChildren: './terms-conditions/terms-conditions.module#TermsConditionsModule'
    },
    {
        path: 'not-found',
        loadChildren: './not-found/not-found.module#NotFoundModule'
    },

    {
        path: 'employers',
        loadChildren: './recruiter-landing/recruiter-landing.module#RecruiterLandingModule'
    },

    {
        path: 'no-data',
        loadChildren: './no-data/no-data.module#NoDataModule'
    },

    {
        path: 'how-it-works',
        loadChildren: './how-itworks/how-itworks.module#HowItworksModule'
    },

    {
        path: 'site-error',
        loadChildren: './error/error.module#ErrorModule'
    },

    {
        path: 'employer',
        loadChildren: './posting/posting.module#PostingModule'
    },

    {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
    },
    {
        path: 'candidate',
        data: {
            breadcrumb: 'Candidate'
        },
        loadChildren: './candidate/candidate.module#CandidateModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'candidate/:id',
        loadChildren:
            './candidate-detail/candidate-detail.module#CandidateDetailModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'candidate-view/:id',
        loadChildren:
            './candidate-view/candidate-view.module#CandidateViewModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'profile-views',
        loadChildren: './profile-view/profile-view.module#ProfileViewModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'recruiter-dashboard',
        loadChildren: './recruiter-dashboard/recruiter-dashboard.module#RecruiterDashboardModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'generate-report',
        loadChildren: './generate-report/generate-report.module#GenerateReportModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'table-report',
        loadChildren: './table-report/table-report.module#TableReportModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'refer',
        loadChildren: './refer/refer.module#ReferModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'preferences',
        loadChildren: './preferences/preferences.module#PreferencesModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'submission',
        loadChildren: './submission/submission.module#SubmissionModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'submission/:id',
        loadChildren:
            './submission-detail/submission-detail.module#SubmissionDetailModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'job-position',
        loadChildren: './job-position/job-position.module#JobPositionModule',
        canActivate: [HiringManagerGuard]
    },
    {
        path: 'job-position/:id',
        loadChildren:
            './job-position-detail/job-position-detail.module#JobPositionDetailModule',
        canActivate: [HiringManagerGuard]
    },
    {
        path: 'position/:id',
        loadChildren: './position-view/position-view.module#PositionViewModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'profile',
        loadChildren: './profile/profile.module#ProfileModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'hm-submission',
        loadChildren: './hm-submission/hm-submission.module#HmSubmissionModule',
        canActivate: [HiringManagerGuard]
    },
    {
        path: 'interviews',
        loadChildren: './interviews/interviews.module#InterviewsModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'interview-calendar',
        loadChildren: './interview-calendar/interview-calendar.module#InterviewCalendarModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'recruiter-profile',
        loadChildren: './recruiter-profile/recruiter-profile.module#RecruiterProfileModule'
    },
    {
        path: 'interview/:id',
        loadChildren: './interview/interview.module#InterviewModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'interview-status/:id',
        loadChildren:
            './interview-status/interview-status.module#InterviewStatusModule',
        canActivate: [CandidateGuard]
    },
    {
        path: 'timesheets-record',
        loadChildren:
            './timesheets-record/timesheets-record.module#TimesheetsRecordModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'invoice-management',
        loadChildren:
            './invoice-management/invoice-management.module#InvoiceManagementModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'invoice-detail',
        loadChildren:
            './invoice-detail/invoice-detail.module#InvoiceDetailModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'expenses',
        loadChildren: './expenses/expenses.module#ExpensesModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'users',
        loadChildren: './users/users.module#UsersModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'organization-team',
        loadChildren: './organization-team/organization-team.module#OrganizationTeamModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'faq',
        loadChildren: './faq/faq.module#FaqModule',
    },
    {
        path: 'expense-approval',
        loadChildren:
            './expense-approval/expense-approval.module#ExpenseApprovalModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'two-factor',
        loadChildren:
            './two-factor/two-factor.module#TwoFactorModule',
    },
    {
        path: 'approval',
        loadChildren: './approval/approval.module#ApprovalModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'approval-detail',
        loadChildren: './approval-detail/approval-detail.module#ApprovalDetailModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'timesheets',
        loadChildren: './timesheets/timesheets.module#TimesheetsModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'defaulters',
        loadChildren: './defaulters/defaulters.module#DefaultersModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'projects',
        loadChildren: './projects/projects.module#ProjectsModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'reports',
        loadChildren: './reports/reports.module#ReportsModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'vendors',
        loadChildren:
            './vendor-management/vendor-management.module#VendorManagementModule',
        canActivate: [HiringManagerGuard]
    },
    {
        path: 'vendor-detail/:id',
        loadChildren: './vendor-detail/vendor-detail.module#VendorDetailModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'documents',
        loadChildren:
            './documents/documents.module#DocumentsModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'calendar',
        loadChildren:
            './interview-calendar/interview-calendar.module#InterviewCalendarModule',
        canActivate: [AuthGuard]
    },
    { path: '**', redirectTo: 'not-found' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
    providers: [AppService]
})
export class AppRoutingModule { }
