import { NoResultsModule } from './components/no-results/no-results.module';
import { HeaderModule } from './components/header/header.module';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard, OuterFooterModule, OuterHeaderModule, HiringManagerGuard, RecruiterGuard } from './shared';
import { JwtInterceptor, ErrorInterceptor, GeolocationService, AuthenticationService} from './shared/services/index';
import { NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
import {BreadcrumbsModule} from 'ng6-breadcrumbs';
import * as $ from 'jquery';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
    // for development
    // return new TranslateHttpLoader(http, '/start-angular/SB-Admin-BS4-Angular-5/master/dist/assets/i18n/', '.json');
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    imports: [
        NgbModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        RouterModule,
        BreadcrumbsModule,
        ToastrModule.forRoot({
            easeTime: 300,
            positionClass: 'toast-top-center',
            progressAnimation: 'decreasing',
            progressBar: true,
            preventDuplicates: true,
            tapToDismiss: true
        }),
        NgbDropdownModule.forRoot(),
        HeaderModule,
        NoResultsModule,
        RouterModule,
        OuterFooterModule,
        OuterHeaderModule
    ],
    declarations: [AppComponent],
    providers: [
        AuthGuard,
        HiringManagerGuard,
        RecruiterGuard,
        AuthenticationService,
        GeolocationService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
    schemas: [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {}
