import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import {User} from './../../_models/model';

@Injectable()
export class RecruiterGuard implements CanActivate {
    currentUser: User;
    constructor(private router: Router) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    canActivate() {
        if (localStorage.getItem('isLoggedin')
         && (this.currentUser.type === 'RECRUITER' || this.currentUser.type === 'HIRING_MANAGER')) {
            return true;
        }
        this.router.navigate(['/access-denied']);
        return false;
    }
}
