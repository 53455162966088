<nav class="navbar navbar-expand-lg fixed-top" *ngIf="currentUser">
    <a class="navbar-brand" href="javascript:void(0)" (click)="clickLogo()" style="margin-left: 8px;">
        <img src="../../../assets/images/white_lg.png" width="156px">
    </a>
    <div class="perLogo1 pull-right" *ngIf="showLogo">
        <img src="../../../assets/images/nike.svg" height="43px">
    </div>
    <button class="navbar-toggler" type="button">
        <!-- <span class="navbar-toggler-icon"></span> -->
        <i class="fa fa-bars text-muted" (click)="openSidebar=!openSidebar" aria-hidden="true"></i>
    </button>

    <div class="collapse navbar-collapse">
        <ul class="navbar-nav ml-auto pull-right">
            <li class="perLogo" *ngIf="showLogo">
                <img src="../../../assets/images/nike.svg" height="43px">
            </li>
            <li class="nav-item dropdown" ngbDropdown>
                <a class="nav-link line49" ngbDropdownToggle>
                    <i class="fa fa-user" style="top:0px;"></i> {{currentUser.firstName}} {{currentUser.lastName}}<b
                        class="caret"></b>
                </a>
                <div class="dropdown-menu-right" ngbDropdownMenu>
                    <a class="dropdown-item" [routerLink]="['/profile']">
                        <i class="fa fa-fw fa-user"></i> {{ 'Account' }}
                    </a>
                    <a class="dropdown-item" [routerLink]="['/faq']">
                        <i class="fa fa-fw fa-envelope"></i> {{ 'FAQs' }}
                    </a>
                    <a class="dropdown-item" [routerLink]="['/preferences']">
                        <i class="fa fa-fw fa-gear"></i> {{ 'Settings' }}
                    </a>
                    <a class="dropdown-item" [routerLink]="['/login']" (click)="logout()">
                        <i class="fa fa-fw fa-power-off"></i> {{ 'Log Out' }}
                    </a>
                </div>
            </li>
        </ul>
    </div>
</nav>
<div [ngClass]="{'fullovly1':currentUser}" *ngIf="openSidebar || openTopbar"
    (click)="openSidebar=false; openTopbar=false"></div>
<aside class="sidebar sidebar-expand-lg" *ngIf="currentUser" [ngClass]="{'myClass' : openSidebar}">
    <nav class="sidebar-navigation ps-container ps-theme-default ps-active-x">
        <ng-scrollbar barClass="scroll-bar" thumbClass="scroll-thumbs" [autoHide]="true">
            <!-- Content -->
            <ul class="menu" *ngIf="currentUser.type === 'RECRUITER' || currentUser.type === 'HIRING_MANAGER'">
                <li class="menu-item " (click)="openSidebar=false">
                    <a class="menu-link" [routerLink]="['/recruiter-dashboard']">
                        <span class="icon ti-dashboard"></span>
                        <span class="title"> {{ 'My Dashboard' }}</span>
                    </a>
                </li>
                <li class="menu-item " (click)="openSidebar=false">
                    <a class="menu-link" [routerLink]="['/approval']">
                        <span class="icon ti-blackboard"></span>
                        <span class="title"> {{ 'My Approvals' }}</span>
                    </a>
                </li>
                <li class="menu-item " (click)="openSidebar=false">
                    <a class="menu-link" [routerLink]="['/candidate']">
                        <span class="icon ti-user"></span>
                        <span class="title"> {{ 'All Candidates' }}</span>
                    </a>
                </li>
                <li class="menu-item" (click)="openSidebar=false">
                    <a class="menu-link" [routerLink]="['/submission']">
                        <span class="icon ti-file"></span>
                        <span class="title"> {{ 'My Submissions' }}</span>
                    </a>
                </li>
                <li class="menu-item" (click)="openSidebar=false" *ngIf="currentUser.companyId">
                    <a class="menu-link" [routerLink]="['/hm-submission']">
                        <span class="icon ti-file"></span>
                        <span class="title"> {{ 'Resumes Received' }}</span>
                    </a>
                </li>
                <li class="menu-item" (click)="openSidebar=false">
                    <a class="menu-link" [routerLink]="['/job-position']">
                        <span class="icon ti-briefcase"></span>
                        <span class="title"> {{ 'Job Positions' }}</span>
                    </a>
                </li>
                <li class="menu-item subMenuItem">
                    <span class="icon ti-agenda subItemPos"></span>
                    <p-accordion class="subTypeHeader">
                        <p-accordionTab header="Reports" class="subBefore">
                            <li class="menu-item" (click)="openSidebar=false">
                                <a class="menu-link">
                                    <span class="icon ti-briefcase"></span>
                                    <span class="title"> Item 1</span>
                                </a>
                            </li>
                            <li class="menu-item" (click)="openSidebar=false">
                                <a class="menu-link">
                                    <span class="icon ti-briefcase"></span>
                                    <span class="title"> Item 2</span>
                                </a>
                            </li>
                            <li class="menu-item" (click)="openSidebar=false">
                                <a class="menu-link">
                                    <span class="icon ti-briefcase"></span>
                                    <span class="title"> Item 3</span>
                                </a>
                            </li>
                        </p-accordionTab>
                </p-accordion>
                </li>
                <li class="menu-item" (click)="openSidebar=false">
                    <a class="menu-link" [routerLink]="['/interviews']">
                        <span class="icon ti-agenda"></span>
                        <span class="title"> {{ 'Interviews' }}</span>
                    </a>
                </li>
                <li class="menu-item" (click)="openSidebar=false" *ngIf="currentUser.companyId">
                    <a class="menu-link" [routerLink]="['/vendors']">
                        <span class="icon ti-panel"></span>
                        <span class="title"> {{ 'My Vendors' }}</span>
                    </a>
                </li>
                <li class="menu-item" (click)="openSidebar=false" *ngIf="currentUser.companyId">
                    <a class="menu-link" [routerLink]="['/organization-team']">
                        <span class="icon ti-layers"></span>
                        <span class="title"> {{ 'My Organization Team' }}</span>
                    </a>
                </li>
                <li class="menu-item" (click)="openSidebar=false" *ngIf="currentUser.companyId">
                    <a class="menu-link" [routerLink]="['/documents']">
                        <span class="icon ti-write"></span>
                        <span class="title"> Contract Documents</span>
                    </a>
                </li>
                <li class="menu-item gosideoff" (click)="openSidebar=false">
                    <a class="menu-link" [routerLink]="['/profile']">
                        <span class="icon ti-user"></span>
                        <span class="title"> {{ 'Profile' }}</span>
                    </a>
                </li>
                <li class="menu-item gosideoff" (click)="openSidebar=false">
                    <a class="menu-link" href="javascript:void(0)">
                        <span class="icon ti-email"></span>
                        <span class="title"> {{ 'Inbox' }}</span>
                    </a>
                </li>
                <li class="menu-item gosideoff" (click)="openSidebar=false">
                    <a class="menu-link" [routerLink]="['/preferences']">
                        <span class="icon ti-settings"></span>
                        <span class="title"> {{ 'Settings' }}</span>
                    </a>
                </li>
                <li class="menu-item gosideoff" (click)="openSidebar=false">
                    <a class="menu-link" [routerLink]="['/login']" (click)="logout()">
                        <span class="icon ti-power-off"></span>
                        <span class="title"> {{ 'Log Out' }}</span>
                    </a>
                </li>
            </ul>
            <ul class="menu" *ngIf="currentUser.type === 'CANDIDATE'">

                <li class="menu-item" (click)="openSidebar=false">
                    <a class="menu-link" [routerLink]="['/joblisting']">
                        <span class="icon ti-search"></span>
                        <span class="title"> {{ 'Search Jobs' }}</span>
                    </a>
                </li>

                <li class="menu-item" (click)="openSidebar=false">
                    <a class="menu-link" [routerLink]="['/submission']">
                        <span class="icon ti-layout-media-center-alt"></span>
                        <span class="title"> {{ 'My Applications' }}</span>
                    </a>
                </li>

                <li class="menu-item" (click)="openSidebar=false">
                    <a class="menu-link" [routerLink]="['/interviews']">
                        <span class="icon ti-briefcase"></span>
                        <span class="title"> {{ 'My Interviews' }}</span>
                    </a>
                </li>

                <li class="menu-item" (click)="openSidebar=false">
                    <a class="menu-link" [routerLink]="['/profile-views']">
                        <span class="icon ti-eye"></span>
                        <span class="title"> {{ 'My Profile Views' }}</span>
                    </a>
                </li>

                <li class="menu-item" (click)="openSidebar=false">
                    <a class="menu-link" [routerLink]="['/candidate', currentUser._id]">
                        <span class="icon ti-user"></span>
                        <span class="title"> {{ 'My Profile' }}</span>
                    </a>
                </li>

                <li class="menu-item" (click)="openSidebar=false">
                    <a class="menu-link" [routerLink]="['/documents']">
                        <span class="icon ti-export"></span>
                        <span class="title"> Documents</span>
                    </a>
                </li>

                <li class="menu-item gosideoff" (click)="openSidebar=false">
                    <a class="menu-link" [routerLink]="['/profile']">
                        <span class="icon ti-user"></span>
                        <span class="title"> {{ 'Profile' }}</span>
                    </a>
                </li>

                <li class="menu-item gosideoff" (click)="openSidebar=false">
                    <a class="menu-link" [routerLink]="['/faq']">
                        <span class="icon ti-user"></span>
                        <span class="title"> {{ 'FAQs' }}</span>
                    </a>
                </li>
                <li class="menu-item gosideoff" (click)="openSidebar=false">
                    <a class="menu-link" [routerLink]="['/preferences']">
                        <span class="icon ti-settings"></span>
                        <span class="title"> {{ 'Settings' }}</span>
                    </a>
                </li>
                <li class="menu-item gosideoff" (click)="openSidebar=false">
                    <a class="menu-link" [routerLink]="['/login']" (click)="logout()">
                        <span class="icon ti-power-off"></span>
                        <span class="title"> {{ 'Log Out' }}</span>
                    </a>
                </li>
            </ul>
        </ng-scrollbar>
    </nav>
</aside>
<div class="main-wrapper" *ngIf="!currentUser">
    <nav id="mainNav" class="navbar navbar-default navbar-custom navbar-fixed-top" style="border: none !important;">
        <div class="container mw1400">
            <div class="" style="width: 100%">

                <div class="navbar-header scrolling" class="head_dis">
                    <button type="button" class="navbar-toggle" (click)="openTopbar=!openTopbar">
                        <span class="sr-only">Toggle navigation</span> <span class="ti-menu"></span>
                    </button>

                    <a [routerLink]="['/']" href="" class="navbar-brand sbrand img-logo scrolling"
                        title=" Smart Submission">
                        <img src="assets/images/white_lg.png" alt="logo">
                    </a>
                </div>

                <!-- Collect the nav links, forms, and other content for toggling -->
                <div class="collapse navbar-collapse" [ngClass]="{'show' : openTopbar}">
                    <ul class="nav navbar-nav navbar-right" style="display: inline; margin-top: 20px;">
                        <!-- <li class="navbar-call-us text-center" (click)="openTopbar=false">
                                <a href="" class="text-white" [routerLink]="['/how-it-works']">How it works</a>
                            </li> -->

                        <li class="navbar-call-us" (click)="openTopbar=false">
                            <button class="btn btn-success btn-block btn-sm"
                                [routerLink]="['/employers']">Employers</button>
                        </li>

                        <li class="navbar-call-us" (click)="openTopbar=false">
                            <button class="btn borbtn btn-block btn-sm" [routerLink]="['/signup']">Sign Up</button>
                        </li>

                        <li class="navbar-call-us" (click)="openTopbar=false">
                            <button class="btn borbtn btn-block btn-sm" [routerLink]="['/login']">Login</button>
                        </li>
                    </ul>
                </div> <!-- end of navbar collapse -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </nav> <!-- end of navigation -->
</div>