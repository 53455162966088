import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {User} from '../../_models/model';

import { AuthenticationService } from '../../shared/services/index';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    currentUserSubscription: Subscription;
    currentUser: User;
    openSidebar: boolean;
    openTopbar: boolean;
    showLogo: boolean;
    constructor(
        private authenticationService: AuthenticationService,
        public router: Router) {
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
        });
    }

    ngOnInit() {}
    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/']);
        window.location.reload();
    }

    clickLogo() {
        if (this.currentUser) {
            if (this.currentUser.type === 'CANDIDATE') {
                this.router.navigate(['/joblisting']);
            } else {
                this.router.navigate(['/recruiter-dashboard']);
            }
        } else {
            this.router.navigate(['/landing']);
        }
    }
}
