import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { User } from './_models/model';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthenticationService } from './shared/services/index';
import { window } from 'rxjs/internal/operators/window';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    currentUser: User;
    currentUserSubscription: Subscription;
    specialPage: boolean;
    private specialPages: any[] = [
      '/intro',
      '/',
      '/login',
      '/signup',
      '/invitation',
      '/forgot-password',
      '/customer-signup',
      '/landing'
    ];
    private currentUrl = '';
    private loggedIn = false;
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService) {
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
            this.router.events.subscribe((route: any) => {
                this.currentUrl = route.url;
                if (!this.currentUser) {
                  this.specialPage = true;
                  this.loggedIn = false;
                } else {
                  this.specialPage = false;
                  this.loggedIn = true;
                }
              });
        });
    }
    ngOnInit() {}
    // @HostListener('window:beforeunload', ['$event'])
    // clearLocalStorage(event) {
        // console.log('Clearing out');
        // localStorage.removeItem('currentUser');
    // }
}
