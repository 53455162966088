import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { OuterHeaderComponent } from './outer-header.component';

@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [OuterHeaderComponent],
    exports: [OuterHeaderComponent]
})
export class OuterHeaderModule {}
